const resData = {
  name: 'Akhilesh Kumar',
  eMail: 'akhileshcoder@gmail.com',
  Phone: '+91 8585965748',
  Address: '235, Koramangala, Bangalore',
  Summary:
    'Seasoned web and application developer with over 12+ years of hands-on experience using' +
    ' the latest web development tools. Proficient in independently building products from inception' +
    ' to production across diverse technologies. A clear communicator with strong organizational skills,' +
    ' I excel in team management, known for influencing core areas and embracing challenges, I specialize in crafting readable,' +
    ' clean, and performant code. Possessing a keen insight into market technologies, I stay abreast of industry' +
    ' trends to contribute to the future landscape.',
  'IT Skills': {
    'Client-Side Technology': [
      {
        Base: ['HTML5', 'CSS3', 'JavaScript (ES 5, 6+)', 'TypeScript', 'Browser Extensions', 'Web Components'],
        'CSS Frameworks / Preprocessor': [
          'Twitter Bootstrap',
          'Skeleton',
          'Spectre',
          'Material design',
          'Less',
          'Sass',
          'PostCSS',
        ],
        'JS Frameworks / Library': [
          'Angular (1.x, 2+, latest)',
          'reactJS (15.x, latest)',
          'redux',
          'VueJS',
          'Hyperapp',
          'Polymer',
          'Knockout',
          'backbone',
          'jQuery',
          'Require.js',
          'Underscore.js',
          'lodash',
          'Turn.js',
          'High Chart',
          'Am Chart',
          'Zing Chart',
          'Chart.js',
          'D3.js',
          'Google-Chart',
          'immutableJS',
        ],
        'Task Runner': ['Grunt', 'Gulp', 'Webpack'],
        'Design Paradigm': ['MVC', 'MVVC', 'Micro-UI', 'Constructor Pattern', 'PubSub', 'Flux', 'V+logic'],
      },
    ],
    'Server-Side Technology': [
      {
        Base: [
          'nodeJS',
          'Express',
          'GoLang',
          'Gin',
          'Gorilla',
          'Java',
          'JSP',
          'php',
          'C++',
          'Shell Scripts',
          'python',
          'Apache Shindig',
        ],
        'Frameworks / Library': [
          'Async',
          'NestJS',
          'hypernova',
          'handlebar',
          'pug',
          'jade',
          'line-by-line',
          'json-schema',
          'Passport',
          'Database Connectors',
          'Stripe',
          'Razorpay',
          'jsCodeShift',
          'gRPC',
        ],
        Database: [
          'MySQL',
          'ORACLE 10g/11g',
          'MongoDB',
          'PostgreSQL',
          'Elastic-Search',
          'noe4j',
          'Informix',
          'BigQuery',
          'DB2',
          'CloudSQL',
          'Hana',
        ],
        'Utility / CI-CD': [
          'Clustered Server',
          'Load Balancer',
          'Docker',
          'Jenkins',
          'Kubernetes',
          'Kafka',
          'Zookeeper',
          'C-Panel',
          'CloudRun',
          'Apache Tomcat',
          'Apache Maven',
          'Gradle',
          'DNS management',
        ],
        Cloud: ['Microsoft Azure', 'GCP', 'AWS (EC2, Cloud9, IAM, S3, Lambda Function, CloudFront, Route53)'],
      },
    ],
    System: {
      'Unit / Integrated Test': [
        'Mocha',
        'chaiJS',
        'shouldJS',
        'Assert',
        'expect',
        'Mocky',
        'Grunt-cefe-mocha',
        'request',
        'Protractor',
        'Jest',
        'Selenium',
        'Robot Framework',
      ],
      'App Development': [
        'Cordova',
        'Cordova Plugin',
        'Hybrid App development',
        'React Native',
        'React Native Plugin Development',
        'Ionic',
        'PhoneGap',
        'Android/IOS native app development',
      ],
      Miscellaneous: [
        'Data Structure',
        'WordPress',
        'Optimize-Press',
        'HTML-email',
        'VOS',
        'VOS Kernel programming',
        'VOS Media Micro-Services',
        'Google API (Translation, GCP, Map etc.)',
        'IOT',
        'Socket.io',
        'WebRTC',
      ],
      'IDEs / Servers / Tools': ['Eclipse', 'Webstorm', 'PyCharm', 'Android Studio', 'XCode', 'Visual Studio'],
      'Version Control Systems': ['Tortoise SVN Client', 'Git', 'GitHub', 'Bitbucket', 'GitLab', 'Git API/webhooks'],
      'Big-Data': ['Data Analysis', 'Data Cleansing', 'Data Optimization', 'Data Processing', 'Data Visualization'],
      'Data Processing Tool': ['Sicence', 'PowerBI', 'Data-Cleaner'],
      Monitoring: ['Mixpannel', 'Data dog', 'Sentry', 'Log-Rocket'],
      'Web Security': [
        'Website Security Analyst',
        'Cryptography',
        'Back-end/Front-end protection',
        'Cybersecurity Essentials',
      ],
      'White Hat': [
        'Web Scanning',
        'Port Scanning',
        'DNS Analysis',
        'Network Analysis',
        'Route Rerouting',
        'Proxy Node',
        'Database Snooping',
        'OS Backdoors',
        'Encryption',
        'Decryption',
        'Removing Fingerprint',
        'Removing Footprint',
      ],
      'Game Engine and Logic': ['Smart-Fox Multiplayer Game Server', 'Pot Logic', 'Game Logic', 'Score Management'],
      'Embedded System & IOT': [
        'JerryScript',
        'Raspberry pi 3',
        'wiring-pi',
        'Microcontroller/Raspberry Pi connection through Web-Interface',
      ],
      'SDLC worked upon': ['Agile Methodology', 'Waterfall', 'Build & Fix'],
      'Web-App Approach': ['PWA', 'Atomic Design', 'MVC', 'MVA', 'SOLID'],
    },
    KEY_HIGHLIGHT: [
      'HTML5',
      'CSS3',
      'JavaScript',
      'reactJS',
      'Angular',
      'VueJS',
      'nodeJS',
      'GoLang',
      'python',
      'Java',
      'All major dataBase',
      'Cloud + Devops skill',
      'People/Project/Growth Management',
    ],
  },
  'Open Source Contribution': {
    'Git (GitHub and GitLab)': [
      'html-email-bible',
      'angular2-bible',
      'Automatic-Web-Project-Builder',
      'nextfootball',
      'csv2jsn',
      'crack-front-endinterview',
      'my-react-class',
      'angularjsClass',
      'angularClass',
      'ang5-trainning',
      'ang1-trainning',
      'react-vppss',
      'uiTrainning',
      'fan',
      'mylogger',
      'car',
      'test_payment',
      'y2j',
      '55+ private repository',
    ],
    'Stack-Overflow (9100+ reputation)': 'https://stackoverflow.com/users/4655241/akhilesh-kumar',
    'Node Package Module (NPM)': [
      'https://www.npmjs.com/package/web-project-builder',
      'https://www.npmjs.com/package/y2j',
    ],
    W3C: 'https://discourse.wicg.io/u/Akhilesh_Kumar',
    'Public Blogs': [
      'https://akhileshcoder.com/blog/index.html',
      'https://edgenetworks.in/2017/04/27/glimpse-javascript-backend-nodejs',
      'https://edgenetworks.in/2017/05/04/part-1-glimpse-javascript-backend-nodejs',
    ],
  },
  'Organizational Experience': {
    'Feb 22 – Present': {
      Position: 'Tech Lead',
      'Company Name': 'Turing (with clients like Siam Makro, propetulies etc)',
      Domain: 'E Commerce',
    },
    'Feb 21 – Feb 22': {
      Position: 'Technical Architect',
      'Company Name': 'Mo-Engage India PVT LTD, Bangalore (India)',
      Domain: 'Customer engagement platform',
    },
    'Sept 18 – Jan 21': {
      Position: 'MTS – 4 (Technical Architect + Team Lead + IC)',
      'Company Name': 'Nutanix, Bangalore (India)',
      Domain: ['Cloud computing', 'Hyper-converged infrastructure appliances'],
    },
    'May 17 – Sept 18': {
      Position: 'Senior Software Engineer',
      'Company Name': 'Cisco Systems, Bangalore (India)',
      Domain: ['Cloud computing', 'Hyper-converged infrastructure appliances'],
    },
    'Jan 11 – May 17': {
      Position: ['Freelancer', 'Prime contributor', 'As a consultant', 'On contract'],
      'Company Name': [
        'AVR Edge Networks (Domain: HR assessment and smart recruiter)',
        'Wealth-pack Advisor PVT LTD (Domain: FinTech, Acquired by Edelweiss)',
        'Gauss Networks(Domain: Playing Card gaming)',
        'WGCZ Holding(xvideos.com)',
        'Porsche Technology',
        'App4pc',
        'Exactuals',
        'Skylo Technology (Domain: IOT, Satellite Communication, Machine Learning) etc.',
      ],
    },
    'Role and Responsibilities': [
      'Collaborating with members for adopting Agile Methodology in the development process',
      'Been responsible for management of multiple teams in with max team size of 20',
      'Suggesting technology-based solutions for enhancing functional efficiency and achieving business excellence',
      'Keeping activities focused on the business change objectives by providing framework for senior management to direct and manage the change process',
      'Understanding process requirements and provided use cases for business,functional & technical requirements',
      ' Establishing healthy business relations and developing excellent rapport to retain customers and providing technical guidance to colleagues & customers',
      'Developing software solutions by studying information needs, conferring with users, studying systems flow, data usage, and work processes',
    ],
    'Significant Accomplishments': {
      'Cisco and Nutanix': [
        'Got multiple CR (candidate recognition) in cisco of amount 800x2, 1600, 5000x2, 8000, 15000x3',
        'Got appraisal before completion of 1 year in both Cisco and Nutanix',
        'Bagged 1st prize in Hack-day, Good Code of conduct and at cisco and 2nd prize at Nutanix in Hackathon',
      ],
      WealthPack:
        'Played a key role in turning-around core project WealthPack and others from scratch to production as freelancer',
      'Gauss Networks':
        'Created two major games (Poker and Teen Patti) from scratch with pot logic and conducted its tournament.',
      'AVR Edge Networks':
        'Secured new clients such as Genpact, HCL, Randstad,Microland,Aricent, Wipro, Virtusa, Dell,ICICI, Persistent through delivering ahead of deadlines.',
      'All Companies': [
        'Improved application efficiency by debugging / revamping several applications across all companies.',
        'Managed release of several products within an extremely short timeline, delivering it for testing ahead of schedule',
      ],
    },
  },
  'Projects Undertaken': {
    'Siam Makro, Domain: e-Commerce': [
      {
        Title: 'Siam Makro (App + Web)',
        Technologies: [
          'HTML5',
          'CSS3',
          'JavaScript',
          'reactJS',
          'nodeJS',
          'GoLang',
          'AWS',
          'CloudSQL',
          'NestJS',
          'CloudRun',
        ],
        Description: 'Scalable E-Commerce mobile and web applications with microservices architecture',
      },
      {
        Title: 'Store expansion Tool',
        Technologies: ['nodeJS', 'GoLang', 'PostgreSQL', 'NestJS'],
        Description: 'Tool for registering and de-registering store outlets',
      },
      {
        Title: 'Thailand Area mapper',
        Technologies: ['nodeJS', 'GoLang', 'Shell Scripts', 'g-map'],
        Description: 'Mapping and re-mapping for different user/store areas',
      },
    ],
    'Mo-Engage, Domain: Customer engagement platform': [
      {
        Title: 'Flows',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'nodeJS', 'python', 'Java', 'Docker'],
        Description: 'UI based Customer action creator and walker',
      },
      {
        Title: 'ATG (Automated Test Generator)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Protractor', 'Selenium', 'Robot Framework'],
        Description:
          'UI based test generator for full featured integration repository with framework like Protractor, Selenium and Robot',
      },
      {
        Title: 'MOE Dashboard',
        Technologies: [
          'HTML5',
          'CSS3',
          'JavaScript',
          'reactJS',
          'TypeScript',
          'nodeJS',
          'python',
          'Java',
          'Docker',
          'BigQuery',
          'CloudRun',
        ],
        Description: 'Customer engagement platform with cloud-based deployment and data analytics',
      },
    ],
    'Nutanix, Domain: Cloud computing, Hyper-converged infrastructure appliances': [
      {
        Title: 'CALM',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable'],
        Description: 'hyper-converged infrastructure appliances and software-defined storage',
      },
      {
        Title: 'CALM Debug and developer Tool',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'Chrome Developer Tool'],
        Description: 'Customized debug tool for CALM',
      },
      {
        Title: 'ATT (Automated testing tool)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable', 'Spectre', 'Chrome Extension'],
        Description: 'UI based auto generated testing tool',
      },
      {
        Title: 'Automated data tree',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable', 'php'],
        Description: 'UI based auto generated 2-way data tree',
      },
      {
        Title: 'Cerebro',
        Technologies: ['nodeJS', 'JerryScript', 'python', 'IOT', 'GoLang'],
        Description: 'Auto snapshot, self-healing, planned healing of PC and clusters',
      },
      {
        Title: 'DRASS (Disaster Recovery as a Service)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable', 'nodeJS', 'python', 'GoLang'],
        Description: 'Disaster Recovery as a Service of PE (Prism element) with help of Cerebro',
      },
      {
        Title: 'Prism',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable', 'php'],
        Description: 'Cloud provider and management tool',
      },
      {
        Title: 'NC (Nutanix Central)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Immutable', 'nodeJS'],
        Description: 'Cloud provider and management tool dashboard',
      },
    ],
    'Cisco Systems, Domain: Networking, Network Security, Unified Intelligence Center': [
      {
        Title: 'CUIC (Cisco Unified Intelligence Center)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'Angular', 'lodash', 'Java', 'Security Analysis'],
        Description:
          'An extension of traditional contact center reporting with dashboard, reports, report definitions,trackers, validators and access panels',
      },
      {
        Title: 'CCX (Contact Center Switchers)',
        Technologies: [
          'nodeJS',
          'python',
          'Shell Scripts',
          'Java',
          'MySQL',
          'VOS',
          'VOS Kernel programming',
          'VOS Media Micro-Services',
          'GoLang',
        ],
        Description:
          'VP (Voice Packets) routing to Agents and lead, VP management during snooping and routing,Fetching history data on the basis of VP Source Details. Round Robbin for agents on cluster by skill, availability, language, rating etc.',
      },
      {
        Title: 'FINEXT',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'Angular', 'Java'],
        Description: 'An application where Agents, Leads, Managers and Admin interacts to client or reports',
      },
      {
        Title: 'CD (Common-Desktop)',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Angular', 'Apache Shindig', 'Java'],
        Description:
          'A generic framework with standard project structure which is being used across Cisco BU and projects to provide consistency across application. All other application provides gadgets/components after overlapping Common Desktop',
      },
      {
        Title: 'CD Toolkit',
        Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'Angular', 'Sass', 'CX-Module'],
        Description: 'All generic plug n play UI components for reactJS, angularJS and angular 2+ applications',
      },
      {
        Title: 'Static Code Analyzer for browser compatibility',
        Technologies: ['HTML5', 'CSS3', 'Angular', 'nodeJS', 'GoLang', 'Git API/webhooks', 'Jenkins', 'Shell Scripts'],
        Description:
          'Application which scan for browser compatibility issues inside application automatically and suggest for its solution. Which glues with local project, hosted git project, git commits, git pull requests and adds comments to corresponding lines if it can cause issue in given set of browsers',
      },
    ],
  },
  'Internships/Trainings': [
    {
      Title: 'Information Security Analysis (at Codec Networks)',
      Period: 'Jun’13 – Aug’13',
      Technologies: [
        'Web Scanning',
        'Port Scanning',
        'DNS Analysis',
        'Network Analysis',
        'Route Rerouting',
        'Proxy Node',
        'Database Snooping',
        'OS Backdoors',
        'Encryption',
        'Decryption',
        'Removing Fingerprint',
        'Removing Footprint',
      ],
    },
    {
      Title: 'Java Level 1 (at Purpleleap)',
      Period: 'Sep’12 – Dec’12',
      Technologies: ['Java'],
    },
    {
      Title: 'Distance Learning (at CMC Ltd.)',
      Period: 'Jun’12 – Jul’12',
      Technologies: ['Java', 'HTML5', 'CSS3', 'JavaScript', 'jQuery', 'MySQL'],
    },
  ],
  'Projects(Self-made or Freelancer or on contract)': [
    {
      Title: 'Attrition Analysis Application - Developed for: AVR Edge Networks',
      Technologies: ['Angular', 'Webpack', 'nodeJS', 'MongoDB'],
      Description:
        'Analysis of candidate’s attrition probability (Chances to leave company) depending upon his market value (Based on knowledge, experience, stack supply and its demand), reason and suggestion for how to retain the candidate',
      Client: 'Genpact',
    },
    {
      Title: 'Hirealchemy (Main Product) - Developed for: AVR Edge Networks',
      Technologies: ['AngularJS', 'reactJS', 'Underscore.js', 'python', 'nodeJS', 'MongoDB', 'Elastic-Search'],
      Description: 'Vacancy Creation, managing candidate status from searching till hiring',
      Client: 'HCL, Randstad, Microland, Aricent, Wipro (Small different named Wipro ISSP)',
    },
    {
      Title: 'IWO (Internal Workforce Optimization) - Developed for: AVR Edge Networks',
      Technologies: ['reactJS', 'TypeScript', 'Webpack'],
      Description:
        'Candidate hiring process management e.g. screener get candidate, TEG Manager propose or reject candidate on several parameters, Interviewer schedule and updates candidate status, Scheduler assign/reassign interviewer slots, Hiring Manager can do anything above other than screener work and hires the candidate',
      Client: 'HCL, Virtusa',
    },
    {
      Title: 'Profile Builder - Developed for: AVR Edge Networks',
      Technologies: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'Angular'],
      Description: 'Profile builder for candidates auto filled information of candidate after uploading resume',
      Client: 'Dell',
    },
    {
      Title: 'Wipro Analytics - Developed for: AVR Edge Networks',
      Technologies: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'Angular'],
      Description: 'Demand creator and optimizer, Hiring Analysis e.g. gap analysis, Seasonality',
      Client: 'Wipro',
    },
    {
      Title: 'Auto sourcing chrome Extension - Developed for: AVR Edge Networks',
      Technologies: ['JavaScript', 'Chrome Extension', 'Gulp'],
      Description:
        'Sourcing candidates from deferent job portals and social pages e.g. Naukri, Shine, LinkedIn,Indeed, GitHub, Facebook, tweeter, SlideShare etc.',
      Client: 'Randstad, ICICI',
    },
    {
      Title: 'Activity Logger and viewer - Developed for: AVR Edge Networks',
      Technologies: ['JavaScript', 'nodeJS', 'MongoDB', 'reactJS', 'TypeScript', 'Webpack'],
      Description:
        'Getting, all activity of user like clicks, http request, thrown error,custom events etc. on any of our product and logging them to DB then show in view(Using Charts), generating reports and userstory from them.',
      Client: 'Randstad, ICICI',
    },
    {
      Title: 'Demand Supply Manager - Developed for: AVR Edge Networks',
      Technologies: ['JavaScript', 'reactJS', 'TypeScript', 'Webpack'],
      Description: 'Managing Several Demand Supply in Persistent',
      Client: 'Persistent',
    },
    {
      Title: 'Global Bulk File Uploader - Developed for: AVR Edge Networks',
      Technologies: ['JavaScript', 'Angular', 'TypeScript', 'Webpack'],
      Description: 'General bulk file upload system which can be used in any application',
      Client: 'HCL, Randstad, Microland, Aricent, Wipro, Persistent, Dell',
    },
    {
      Title: 'Teen Patti & Poker - Developed for: Gauss Networks',
      Description: 'It is an online playing card game.',
      Technologies: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'Angular',
        'lodash',
        'MySQL',
        'nodeJS',
        'Mocha',
        'Grunt',
        'Grunt-cefe-mocha',
        'chaiJS',
        'shouldJS',
        'Assert',
        'request',
      ],
    },
    {
      Title: 'WealthPack - Developed for: WealthPack Adviser PVT Ltd',
      Description:
        'A Web App and Android app to track analyze and control over financial expense of user in interactive environment by getting authentication from any of their Email (Gmail, Yahoo etc.),SMS services, android pocket app, mac pocket app accounts.',
      Technologies: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'jQuery',
        'Angular',
        'Underscore.js',
        'Twitter Bootstrap',
        'Java',
        'Security Analysis',
        'Big Data',
      ],
    },
    {
      Title: 'Internal Control and Categorizing S/W - Developed for: WealthPack Adviser PVT Ltd',
      Description:
        'Intra network Web Application for categorization of companies with identification of employees and machine they are working on in contrast of coordinate area in which companies are lying and re-categorization of companies with history maintained.',
      Technologies: [
        'HTML5',
        'CSS3',
        'JavaScript',
        'jQuery',
        'Angular',
        'Underscore.js',
        'Twitter Bootstrap',
        'Java',
        'MySQL',
      ],
    },
    {
      Title: 'Media payment management - Developed for: Exactuals',
      Description:
        'Payment management between production house, Exactuals, CNB (city national bank), guild, end talent',
      Technologies: [
        'Java',
        'HTML5',
        'CSS3',
        'JavaScript',
        'jQuery',
        'Bootstrap',
        'Angular',
        'nodeJS',
        'AWS',
        'Lambda',
        'Java',
        'PostgreSQL',
      ],
    },
    {
      Title: 'Operation Portal - Developed for: Skylo',
      Description:
        'Operational Portal where bidirectional tracking analyzing and communication can be done between HUB (IOT devices) and N-Level operators over satellite/socket/http/wi-fi/Bluetooth',
      Technologies: ['HTML5', 'CSS3', 'JavaScript', 'reactJS', 'TypeScript', 'nodeJS'],
    },
    {
      Title: 'Domain Mapper: as freelancer for WGCZ Holding',
      Technologies: ['nodeJS', 'reactJS'],
      Description: 'Multiple sandbox web-app hosting on single server with single IP',
    },
    {
      Title: 'xVideos: as freelancer for WGCZ Holding',
      Technologies: ['nodeJS', 'reactJS'],
      Description: 'Media Management template application',
    },
    {
      Title: 'Musiplus (Compose Music Online & Other Fun Activity): A hobby project',
      Description: 'Compose Music online & other fun activity',
      Technologies: ['HTML5', 'CSS3', 'JavaScript', 'jQuery', 'Java', 'MySQL'],
    },
    {
      Title: 'Criminal Record Management System (Submitted to IBM TGMC-2012): A hobby project',
      Description: 'Maintain criminal’s records, traverse records by face, fingerprint & retina image',
      Technologies: ['Java', 'HTML5', 'CSS3', 'JavaScript', 'jQuery'],
    },
    {
      Title: 'Chess (without AI), Tic-tac-toe (with AI), various hacking tools and viruses: A hobby project',
      Technologies: ['Java', 'Shell Scripts'],
    },
    {
      Title: 'Online WhatsApp (Enhanced version for popular WhatsApp apps): A hobby project',
      Description: 'Enhanced version for popular Android app WhatsApp',
      Technologies: [
        'Java',
        'HTML5',
        'CSS3',
        'JavaScript',
        'jQuery',
        'Bootstrap',
        'Angular',
        'Google Map and Search API',
      ],
    },
    {
      Title: 'Apper (Make apps for windows, android, iOS with few clicks on web): A hobby project',
      Description: 'Make apps for Windows, Android, iOS with few clicks on web',
      Technologies: ['Ionic', 'nodeJS', 'reactJS', 'Gulp', 'Chart.js', 'Shell Scripts'],
    },
    {
      Title: 'Internal Search Engine: A hobby project',
      Description: 'Intranet search engine for internal applications and pages',
      Technologies: ['nodeJS', 'MongoDB', 'Data Structure'],
    },
    {
      Title: 'Best Deal: for an startup best deal',
      Description:
        'Providing deal, product, offer over searched query using exposed API several trip/Ecommerce/coupon websites',
      Technologies: ['nodeJS', 'Express', 'JavaScript', 'Angular'],
    },
    {
      Title: 'Robot Control system using web interface: A hobby project',
      Description:
        'Controlling Robots using web interface with live Camera and GPS update, which was extended to crawl website remotely on different networks in case IP is getting blocked or load for heavy traffic',
      Technologies: ['Raspberry pi 3', 'nodeJS', 'Angular', 'wiring-pi', 'Socket.io'],
    },
    {
      Title: 'Chatbox: A hobby project',
      Description: 'Realtime chat integrated with full replica of android on web',
      Technologies: [
        'Java',
        'python',
        'nodeJS',
        'HTML5',
        'CSS3',
        'JavaScript',
        'jQuery',
        'Bootstrap',
        'Angular',
        'nodeJS',
      ],
    },
  ],
  'Academic Details/Achievements': {
    '2007': '10th from K.H.S. High School, Secured 65%',
    '2009': '12th from S.N.S. College, Secured 67.6%',
    '2014':
      'B. Tech. (Information Technology) from Dr. Akhilesh Das Gupta Institute of   Technology & Management New Delhi, G.G.S.I.P.U. secured 69.13%',
    Achievements: [
      'Bagged first prize in College, District, and State Level Debate competitions',
      'Selected in IIT JEE-2009 (E.M.L.)',
      "Awarded by 2nd prize in 'Playing Card (Rummy and Poker both)' competition and 1st prize in 'Chess' and 'Dare IT' competition at NIEC during B.Tech.",
      'Appeared in eLitmus and scored verbal (51.16), problem solving (95.31), quantitative (91.49) percentiles',
    ],
  },
  'Personal Details': {
    'Languages Known': ['English', 'Hindi', 'Sanskrit'],
    'Marital Status': 'Single (No dependents)',
    'Passport Details': 'Indian, Valid till November 2025',
  },
};

export { resData };
